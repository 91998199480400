import { Dispatch, ReactElement, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import styled from '@emotion/styled';

/*
 * SCROLL ZONES
 * A line's scroll zone is the number of pixels you can scroll through while that line is visible and being animated, before it disappears and we start on the next line. Note that the first line's scroll zone is half as big as the others, because it's already fully opaque when we load the page, so we can skip the first half of the animation (fading-in). Similar for the last line.
 * */

type TextScrollAnimationProps = {
  onLineChange: Dispatch<number>;
  onScrollPastLastLine: () => void;
  instanceId: string;
  children: ReactElement[];

  // higher means you have to scroll for longer to get
  lineScrollZoneLength: number;
  // 0-1, higher means text moves further
  translateYCoefficient: number;
  // 0-1, higher means more of the scroll zone is spent fading
  fadeZoneFraction?: number;
};

const TextScrollAnimation = ({
  children: lines,
  onLineChange,
  onScrollPastLastLine,
  instanceId,
  lineScrollZoneLength,
  fadeZoneFraction = 0.25,
  translateYCoefficient,
}: TextScrollAnimationProps) => {
  const linesRefDefault = new Array(lines.length);
  const linesRef = useRef(linesRefDefault);

  useEffect(() => {
    // current internal scroll pos that we're using in calculations
    let currentScrollPos = 0;
    // target is the actual latest scroll pos measured in the browser
    let targetScrollPos = 0;
    // keep track of current requestAnimationFrame ID
    let reqAnimFrameId = null;

    const HALF_SCROLL_ZONE_LENGTH = lineScrollZoneLength / 2;
    const FADE_ZONE_LENGTH = fadeZoneFraction * lineScrollZoneLength;
    const SUBSEQUENT_LINE_OFFSET =
      HALF_SCROLL_ZONE_LENGTH * translateYCoefficient;

    // when they scroll past the last line
    const LAST_LINE_THRESHOLD =
      HALF_SCROLL_ZONE_LENGTH + (lines.length - 1) * lineScrollZoneLength;

    const setTranslateY = (
      lineIndex: number,
      scrollZoneStart: number,
      offset: number,
    ) => {
      const distanceScrolled = currentScrollPos - scrollZoneStart;
      const translateY = -1 * distanceScrolled * translateYCoefficient + offset;
      const transform = `translateY(${translateY}px)`;
      linesRef.current[lineIndex].style.transform = transform;
      linesRef.current[lineIndex].style.WebkitTransform = transform;
    };

    const setOpacity = (lineIndex: number, opacity: number) => {
      linesRef.current[lineIndex].style.opacity = opacity;
    };

    const lineIsAnimating = (scrollZoneStart: number, scrollZoneEnd: number) =>
      currentScrollPos >= scrollZoneStart && currentScrollPos < scrollZoneEnd;

    const lineIsFadingIn = (doFadeIn: boolean, scrollZoneStart: number) => {
      const fadeInZoneEnd = scrollZoneStart + FADE_ZONE_LENGTH;
      return doFadeIn && currentScrollPos < fadeInZoneEnd;
    };
    const lineIsFadingOut = (doFadeOut: boolean, scrollZoneEnd: number) => {
      const fadeOutZoneStart = scrollZoneEnd - FADE_ZONE_LENGTH;
      return doFadeOut && currentScrollPos >= fadeOutZoneStart;
    };

    const setFadingInOpacity = (lineIndex: number, scrollZoneStart: number) => {
      const fadeZonePosition = currentScrollPos - scrollZoneStart;
      setOpacity(lineIndex, fadeZonePosition / FADE_ZONE_LENGTH);
    };

    const setFadingOutOpacity = (lineIndex: number, scrollZoneEnd: number) => {
      const fadeOutZoneStart = scrollZoneEnd - FADE_ZONE_LENGTH;
      const fadeZonePosition = currentScrollPos - fadeOutZoneStart;
      setOpacity(lineIndex, 1 - fadeZonePosition / FADE_ZONE_LENGTH);
    };

    const lineStayingVisible = (
      scrollZoneStart: number,
      scrollZoneEnd: number,
      doFadeIn: boolean,
      doFadeOut: boolean,
    ) =>
      (!doFadeOut && currentScrollPos > scrollZoneEnd) ||
      (!doFadeIn && currentScrollPos < scrollZoneStart);

    const animateLine = ({
      lineIndex,
      scrollZoneStart = 0,
      scrollZoneLength = lineScrollZoneLength,
      doFadeIn = true,
      doFadeOut = true,
      offset = 0,
    }) => {
      const scrollZoneEnd = scrollZoneStart + scrollZoneLength;

      if (lineIsAnimating(scrollZoneStart, scrollZoneEnd)) {
        onLineChange(lineIndex);

        if (lineIsFadingIn(doFadeIn, scrollZoneStart)) {
          setFadingInOpacity(lineIndex, scrollZoneStart);
        } else if (lineIsFadingOut(doFadeOut, scrollZoneEnd)) {
          setFadingOutOpacity(lineIndex, scrollZoneEnd);
        } else {
          setOpacity(lineIndex, 1);
        }
        setTranslateY(lineIndex, scrollZoneStart, offset);
      } else if (
        lineStayingVisible(scrollZoneStart, scrollZoneEnd, doFadeIn, doFadeOut)
      ) {
        setOpacity(lineIndex, 1);
      } else {
        setOpacity(lineIndex, 0);
      }
    };

    const animateAllLines = () => {
      lines.forEach((line, lineIndex) => {
        if (lineIndex === 0) {
          // first line
          animateLine({
            lineIndex,
            scrollZoneLength: HALF_SCROLL_ZONE_LENGTH,
            doFadeIn: false,
          });
        } else {
          const scrollZoneStart =
            HALF_SCROLL_ZONE_LENGTH + (lineIndex - 1) * lineScrollZoneLength;
          if (lineIndex === lines.length - 1) {
            // last line
            animateLine({
              lineIndex,
              scrollZoneStart,
              scrollZoneLength: HALF_SCROLL_ZONE_LENGTH,
              doFadeOut: false,
              offset: SUBSEQUENT_LINE_OFFSET,
            });
          } else {
            // middle lines
            animateLine({
              lineIndex,
              scrollZoneStart,
              offset: SUBSEQUENT_LINE_OFFSET,
            });
          }
        }
      });
      // when they scroll past the last line
      if (currentScrollPos > LAST_LINE_THRESHOLD) {
        onScrollPastLastLine();
      }
    };

    const updateAnimation = () => {
      // while scrolling, targetScrollPos will be constantly changing
      const diff = targetScrollPos - currentScrollPos;
      const inScrollZone = currentScrollPos < LAST_LINE_THRESHOLD;
      currentScrollPos = targetScrollPos;

      if (diff && inScrollZone) {
        // queue up the next update
        reqAnimFrameId = requestAnimationFrame(updateAnimation);
      } else {
        // finish the current animation loop
        cancelAnimationFrame(reqAnimFrameId);
        reqAnimFrameId = null;
      }

      animateAllLines();
    };

    const startAnimation = () => {
      // if we're not already in a RAF animation loop, then start a new one
      if (!reqAnimFrameId) {
        reqAnimFrameId = requestAnimationFrame(updateAnimation);
      }
    };

    const handleScroll = () => {
      targetScrollPos = window.scrollY || window.pageYOffset;
      const inScrollZone = targetScrollPos < LAST_LINE_THRESHOLD;
      if (inScrollZone) {
        startAnimation();
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 25);
    window.addEventListener('scroll', throttledHandleScroll);

    // cleanup on unmount
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      if (reqAnimFrameId) {
        cancelAnimationFrame(reqAnimFrameId);
      }
    };
  }, []);
  return (
    <>
      {lines.map((line, i) => (
        <Line
          key={`${instanceId}${line.key}`}
          ref={el => {
            linesRef.current[i] = el;
          }}
        >
          {line}
        </Line>
      ))}
    </>
  );
};

export default TextScrollAnimation;

const Line = styled.div`
  position: absolute;

  /* initial values */
  opacity: 0;
  &:first-of-type {
    opacity: 1;
  }
`;
