import { useEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { SplashAssets } from '@app/api/resources/AppConfig';
import { Film } from '@app/api/resources/Film';
import {
  getMubiGoProgrammings,
  MubiGoProgramming,
} from '@app/api/resources/MubiGo';

import { grecaptchaAction } from '@app/services/grecaptcha';
import {
  getSplashContentPropsForCountry,
  initialiseTrendingFilms,
} from '@app/services/page-initialisation/splash';
import {
  doRedirect,
  getFullRouteUrl,
  handleCountryPrefixRedirect,
} from '@app/services/routeHelpers';

import { Context } from '@app/types/common';

import useI18nUrlData, {
  getI18nUrlDataFromState,
} from '@app/hooks/helpers/useI18nUrlData';

import { redirectToNowShowing } from '@app/services/initialisation';

import SeoMetaTags from '@app/components/SeoMetaTags';
import SocialMetaTags from '@app/components/SocialMetaTags';
import SplashHead from '@app/components/splash/splash-head/SplashHead';
import SplashCommunitySection from '@app/components/splash/SplashCommunitySection';
import SplashDevicesSection from '@app/components/splash/SplashDevicesSection';
import SplashFilmsSection from '@app/components/splash/SplashFilmsSection';
import SplashFooterContainer from '@app/components/splash/SplashFooterContainer';
import SplashMubiGoNew from '@app/components/splash/SplashMubiGoNew';
import SplashNotebookSection from '@app/components/splash/SplashNotebookSection';
import StandardHeadTags from '@app/components/StandardHeadTags';
import SplashAndShowingTrackingPx from '@app/components/tracking-pixels/SplashAndShowingTrackingPx';
import TrackScrollEventWhenInView from '@app/components/TrackScrollEventWhenInView';

type SplashProps = {
  devicesImageFilename: string;
  communityImageFilename: string;
  trendingFilmsCopy: string;
  trendingFilms?: Film[];
  splashVideos?: SplashAssets['video'];
  isRedirecting?: boolean;
  mubiGoProgrammingOfTheWeek?: MubiGoProgramming;
};

const Splash = ({
  devicesImageFilename,
  communityImageFilename,
  trendingFilmsCopy,
  mubiGoProgrammingOfTheWeek,
  trendingFilms = [],
  splashVideos = {
    h264: {
      mobile_url: '',
      tablet_url: '',
      desktop_url: '',
    },
    h265: {
      mobile_url: '',
      tablet_url: '',
      desktop_url: '',
    },
    mobile_url: '',
    tablet_url: '',
    desktop_url: '',
  },
  isRedirecting = false,
}: SplashProps) => {
  const { t } = useTranslation('common');
  useEffect(() => {
    const doGrecaptchaAction = async (action: string) => {
      try {
        await grecaptchaAction(action);
      } catch (error) {
        // ignore error, already reported to Sentry in grecaptchaAction
      }
    };

    doGrecaptchaAction('view_splash');
  }, []);
  const i18nUrlData = useI18nUrlData();

  const image = `${process.env.assetPrefix}/splash/volver.jpg`;
  const url = getFullRouteUrl({
    url: '/',
    i18nUrlData,
  });

  if (isRedirecting) {
    return null;
  }

  return (
    <>
      <Head>
        <meta
          name="ahrefs-site-verification"
          content="b9d3d641b44eb4b4477accd8888390fb13c645b1c81802d7434532a25069015b"
        />
      </Head>
      <SplashAndShowingTrackingPx />
      <StandardHeadTags title={t('splash:splash.title')} />
      <SeoMetaTags canonicalUrl={url} image={image} />
      <SocialMetaTags
        title={t('splash:splash.title')}
        url={url}
        image={image}
      />
      <SplashHead splashVideos={splashVideos} />
      <SplashBelowTheFold>
        <ParallaxProvider>
          <TrackScrollEventWhenInView
            scrollDetail={{
              scroll_type: 'splash_scrolled_past_fold',
              scroll_direction: 'vertical',
              page_type: 'splash',
              reached_end: false,
            }}
          >
            <SplashDevicesSection imageFilename={devicesImageFilename} />
          </TrackScrollEventWhenInView>
          <SplashFilmsSection
            shortLogo
            films={trendingFilms}
            name={trendingFilmsCopy}
          />
          {mubiGoProgrammingOfTheWeek ? (
            <SplashMubiGoNew
              mubiGoProgrammingOfTheWeek={mubiGoProgrammingOfTheWeek}
            />
          ) : null}
          <SplashNotebookSection />
          <SplashCommunitySection imageFilename={communityImageFilename} />
          <TrackScrollEventWhenInView
            scrollDetail={{
              scroll_type: 'reached_end',
              scroll_direction: 'vertical',
              page_type: 'splash',
              reached_end: true,
            }}
            threshold={0.5}
          >
            <SplashFooterContainer />
          </TrackScrollEventWhenInView>
        </ParallaxProvider>
      </SplashBelowTheFold>
    </>
  );
};

Splash.getInitialProps = async (ctx: Context): Promise<SplashProps> =>
  getInitialProps(ctx);

export const getInitialProps = async (ctx: Context): Promise<SplashProps> => {
  const { store, isServer, res, query } = ctx;
  let initialProps = {} as SplashProps;

  const currentState = store.getState();
  const shouldRedirectAsCountryPrefixInvalidOrMissing =
    handleCountryPrefixRedirect(ctx);

  if (shouldRedirectAsCountryPrefixInvalidOrMissing) {
    // Delete vanityPath from query in case we need to redirect country. We avoid this case:  we are in ES then /es/it -> /es/es?vanityPath=it
    const queryParams = { ...query } as Record<string, string>;
    if (queryParams['vanityPath']) {
      delete queryParams['vanityPath'];
    }
    doRedirect(
      isServer,
      getFullRouteUrl({
        url: '/',
        i18nUrlData: getI18nUrlDataFromState(currentState),
        includeDomain: false,
        queryParams,
      }),
      res,
    );

    initialProps.isRedirecting = true;
    return initialProps;
  }

  const httpContext = currentState?.appState?.httpContext;
  const isAuthenticated = currentState?.user?.isAuthenticated;
  const country = currentState?.user?.geoLocation;
  const splashVideos = currentState?.appState?.pageState?.splash?.videos;

  // redirect to Now Showing page if logged-in
  if (isAuthenticated) {
    redirectToNowShowing(ctx);
    initialProps.isRedirecting = true;
    return initialProps;
  }

  const contentProps = getSplashContentPropsForCountry(country);

  let mubiGoProgrammingOfTheWeek = null;
  let trendingFilms = [];

  try {
    const response = await Promise.allSettled([
      getMubiGoProgrammings(httpContext),
      initialiseTrendingFilms(httpContext, country),
    ]);
    if (response[0].status === 'fulfilled') {
      const mubiGoProgrammings = response[0].value.data;
      mubiGoProgrammingOfTheWeek =
        mubiGoProgrammings.find(programming => programming.film_of_the_week) ||
        mubiGoProgrammings[0];
    }
    if (response[1].status === 'fulfilled') {
      trendingFilms = response[1].value;
    }
  } catch (error) {
    // Client-Country without Mubi GO will return an error
    // Also, ignore trending films error
  }

  initialProps = {
    splashVideos,
    mubiGoProgrammingOfTheWeek,
    trendingFilms,
    ...contentProps,
  };

  return initialProps;
};

const SplashBelowTheFold = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: ${props => props.theme.color.white};
`;

export default Splash;
