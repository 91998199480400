import styled from '@emotion/styled';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import SplashFooter from '@app/components/splash/SplashFooter';
import SplashFormCTA from '@app/components/splash/SplashFormCTA';

const SplashFooterContainer = () => {
  const trialsEnabled = useAppSelector(state => state.appState.trialsEnabled);
  return (
    <SplashFooter
      cta={
        <CtaPosition>
          <StyledSplashFormCTA
            snowplowElement="cta_bottom"
            textColor="black"
            hidePrice
            trialsEnabled={trialsEnabled}
          />
        </CtaPosition>
      }
    />
  );
};

const StyledSplashFormCTA = styled(SplashFormCTA)<{ trialsEnabled: boolean }>`
  @media (min-width: ${props => props.theme.mq.tablet}) {
    ${({ trialsEnabled }) => !trialsEnabled && 'font-size: 20px;'}
  }
`;

const CtaPosition = styled.div`
  width: 100%;
  max-width: 625px;
`;

export default SplashFooterContainer;
