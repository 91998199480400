import styled from '@emotion/styled';

type VerticalDotNavProps = {
  numberOfDots: number;
  currentDot: number;
  instanceId: string;
};

const VerticalDotNav = ({
  numberOfDots,
  currentDot,
  instanceId,
}: VerticalDotNavProps) => (
  <Dots>
    {[...Array(numberOfDots)].map((n, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Dot key={`${instanceId}${i}`} highlight={i === currentDot} />
    ))}
  </Dots>
);

const Dots = styled.div`
  display: flex;
  flex-direction: column;
`;

const Dot = styled.div<{ highlight: boolean }>`
  width: 10px;
  height: 10px;
  margin: 5px 0;
  border-radius: 50%;
  background-color: ${props =>
    props.highlight ? props.theme.color.white : props.theme.color.midGray};
`;

export default VerticalDotNav;
