import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { checkPathnameArrayIncludesPathname } from '@app/services/routeHelpers';
import { isDevelopment } from '@app/services/utils';

import useCookiePrefs from '@app/hooks/helpers/useCookiePrefs';

const checkIfIsPageToShowTrackingPixelOn = router => {
  const isSplashOrShowingPage = checkPathnameArrayIncludesPathname(
    ['/', '/showing'],
    router.pathname,
  );

  if (isSplashOrShowingPage) {
    return true;
  }

  return false;
};

const checkIfShouldShowAmazonTrackingPixel = router => {
  const hasAmazonUtmSource = router?.query?.utm_source === 'amazon';
  const hasAmazonUtmMedium = router?.query?.utm_medium === 'display';

  if (hasAmazonUtmSource && hasAmazonUtmMedium) {
    return true;
  }

  return false;
};

const isAmazonCampaignUkOrUs = router => {
  const amazonUtmCampaign = router?.query?.utm_campaign;
  if (amazonUtmCampaign.includes('_uk')) {
    return 'uk';
  }
  if (amazonUtmCampaign.includes('_us')) {
    return 'us';
  }

  return null;
};

const checkIfShouldShowRedditTrackingPixel = router => {
  const hasRedditUtmSource = router?.query?.utm_source === 'reddit';
  const hasRedditUtmMedium = router?.query?.utm_medium === 'cpc';

  if (hasRedditUtmSource || hasRedditUtmMedium) {
    return true;
  }

  return false;
};

/* eslint-disable */
const displayAmazonTrackingPixelUK = () => {
  var _pix = document.getElementById(
    '_pix_id_e45c0906-0828-7427-1fe0-7ef756268458',
  );
  if (!_pix) {
    var protocol = '//';
    var a = Math.random() * 1000000000000000000;
    _pix = document.createElement('iframe');
    _pix.style.display = 'none';
    _pix.setAttribute(
      'src',
      protocol +
        'aax-eu.amazon-adsystem.com/s/iu3?d=generic&ex-fargs=%3Fid%3De45c0906-0828-7427-1fe0-7ef756268458%26type%3DUNKNOWN%26m%3D3&ex-fch=416613&ex-src=https://mubi.com/&ex-hargs=v%3D1.0%3Bc%3D4833553090102%3Bp%3DE45C0906-0828-7427-1FE0-7EF756268458' +
        '&cb=' +
        a,
    );
    _pix.setAttribute('id', '_pix_id_e45c0906-0828-7427-1fe0-7ef756268458');
    document.body.appendChild(_pix);
  }
};

const displayAmazonTrackingPixelUS = () => {
  var _pix = document.getElementById(
    '_pix_id_f5199b40-e313-6933-dd5c-7037dd7d23b9',
  );
  if (!_pix) {
    var protocol = '//';
    var a = Math.random() * 1000000000000000000;
    _pix = document.createElement('iframe');
    _pix.style.display = 'none';
    _pix.setAttribute(
      'src',
      protocol +
        's.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Df5199b40-e313-6933-dd5c-7037dd7d23b9%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://mubi.com/&ex-hargs=v%3D1.0%3Bc%3D7035093580301%3Bp%3DF5199B40-E313-6933-DD5C-7037DD7D23B9' +
        '&cb=' +
        a,
    );
    _pix.setAttribute('id', '_pix_id_f5199b40-e313-6933-dd5c-7037dd7d23b9');
    document.body.appendChild(_pix);
  }
};

const displayRedditTrackingPixel = () => {
  if (!window.rdt) {
    var p = (window.rdt = function () {
      p.sendEvent
        ? p.sendEvent.apply(p, arguments)
        : p.callQueue.push(arguments);
    });
    p.callQueue = [];
    var t = document.createElement('script');
    (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(t, s);
  }

  if (window.rdt) {
    window.rdt('init', 't2_ayheiulv');
    window.rdt('track', 'PageVisit');
  }
};
/* eslint-enable */

const SplashAndShowingTrackingPx = () => {
  const router = useRouter();

  const [hasInitialisedPixel, setHasInitialisedPixel] = useState(false);
  const { hasConsentedToCookie } = useCookiePrefs();

  useEffect(() => {
    if (!isDevelopment && !hasInitialisedPixel) {
      setHasInitialisedPixel(true);

      if (
        hasConsentedToCookie !== false &&
        checkIfIsPageToShowTrackingPixelOn(router)
      ) {
        if (checkIfShouldShowAmazonTrackingPixel(router)) {
          const ukOrUsCampaign = isAmazonCampaignUkOrUs(router);
          if (ukOrUsCampaign === 'uk') {
            displayAmazonTrackingPixelUK();
          } else if (ukOrUsCampaign === 'us') {
            displayAmazonTrackingPixelUS();
          }
        } else if (checkIfShouldShowRedditTrackingPixel(router)) {
          displayRedditTrackingPixel();
        }
      }
    }
  }, []);

  return null;
};

export default SplashAndShowingTrackingPx;
