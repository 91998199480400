import * as Sentry from '@sentry/nextjs';

declare let window: {
  grecaptcha: {
    enterprise: ReCaptchaV2.ReCaptcha;
  };
};

const TIME_TO_WAIT_FOR_GRECAPTCHA_TO_LOAD = 5000;

const waitForGrecaptcha = async () =>
  new Promise<void>(resolve => {
    const intervalId = setInterval(() => {
      if (window.grecaptcha) {
        clearInterval(intervalId);
        resolve();
      }
    }, 200);
  });

export const grecaptchaAction = async (action: string) =>
  new Promise<void>(async (resolve, reject) => {
    if (process.env.MUBI_ENV === 'production') {
      const timeoutId = setTimeout(() => {
        Sentry.captureException(
          new Error(`grecaptcha is undefined, unable to report: ${action}`),
        );
        reject();
      }, TIME_TO_WAIT_FOR_GRECAPTCHA_TO_LOAD);

      await waitForGrecaptcha();

      grecaptcha.enterprise.ready(async () => {
        clearTimeout(timeoutId);
        await grecaptcha.enterprise.execute(process.env.recaptchaSiteKey, {
          action,
        });
        resolve();
      });
    } else {
      resolve();
    }
  });

export const getGrecaptchaTokenForAction = (action: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject();
    }, TIME_TO_WAIT_FOR_GRECAPTCHA_TO_LOAD);
    grecaptcha.enterprise.ready(async () => {
      clearTimeout(timeoutId);
      const grecaptchaToken = await grecaptcha.enterprise.execute(
        process.env.recaptchaSiteKey,
        {
          action,
        },
      );
      resolve(grecaptchaToken);
    });
  });
