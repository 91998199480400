import { ReactNode } from 'react';
import { InView } from 'react-intersection-observer';

import useSnowplowScrollTracker from '@app/hooks/snowplow/useSnowplowScrollTracker';

type TrackScrollEventWhenInViewProps = {
  children: ReactNode;
  scrollDetail: {
    scroll_type: string;
    scroll_direction: string;
    page_type: string;
    reached_end?: boolean;
    description?: string;
    page_url?: string;
    film_id?: number;
  };
  threshold?: number;
};

const TrackScrollEventWhenInView = ({
  children,
  scrollDetail,
  threshold = 0,
}: TrackScrollEventWhenInViewProps) => {
  const trackSnowplowScrollEvent = useSnowplowScrollTracker();

  const onInView = (inView: boolean) => {
    if (inView) {
      trackSnowplowScrollEvent(scrollDetail);
    }
  };

  return (
    <InView triggerOnce threshold={threshold} onChange={onInView}>
      {children}
    </InView>
  );
};

export default TrackScrollEventWhenInView;
