import Svg from '@app/components/icons/Svg';

const GoTicketSvg = () => (
  <Svg>
    <clipPath id="clip-path">
      <path d="M10.63 0v165H0v-5.07c.095.005.192.008.29.008 2.485 0 4.5-1.993 4.5-4.452 0-2.458-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.01v-7.219c.095.006.192.01.29.01 2.485 0 4.5-1.994 4.5-4.453 0-2.459-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.01v-7.22c.095.007.192.01.29.01 2.485 0 4.5-1.993 4.5-4.452 0-2.459-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.009v-7.218c.095.006.192.009.29.009 2.485 0 4.5-1.993 4.5-4.452 0-2.46-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.009v-7.219c.095.007.192.01.29.01 2.485 0 4.5-1.994 4.5-4.453 0-2.458-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.01v-7.219c.095.006.192.01.29.01 2.485 0 4.5-1.994 4.5-4.453 0-2.459-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.01v-7.219c.095.006.192.01.29.01 2.485 0 4.5-1.994 4.5-4.453 0-2.459-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.01v-7.22c.095.007.192.01.29.01 2.485 0 4.5-1.993 4.5-4.452 0-2.459-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.009v-7.218c.095.006.192.009.29.009 2.485 0 4.5-1.994 4.5-4.452 0-2.46-2.015-4.452-4.5-4.452-.098 0-.195.003-.29.009l-.001-7.222c.112.008.225.013.338.013 2.512 0 4.549-2.015 4.549-4.5C4.886 8.015 2.849 6 .337 6c-.113 0-.226.004-.338.012L0 0h10.63Z" />
    </clipPath>
  </Svg>
);

export default GoTicketSvg;
