import { shallowEqual } from 'react-redux';

import { trackSnowplowScrollEvent } from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useSnowplowScrollTracker = () => {
  const { httpContext, countryCode } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
    }),
    shallowEqual,
  );

  const doTrackSnowplowScrollEvent = eventData => {
    trackSnowplowScrollEvent(
      {
        ...eventData,
        country_code: countryCode,
      },
      httpContext,
    );
  };

  return doTrackSnowplowScrollEvent;
};

export default useSnowplowScrollTracker;
